import { AuthConfig } from '@auth0/auth0-angular';

export const environment = {
  production: false,
  apiBaseUrl: 'https://api-agents.dev.jplus.io',
  authConfig: {
    domain: 'jellyfish-dev.eu.auth0.com',
    clientId: '1hzQQr1Sfcijw769fl871V1BfGsFkDmp',
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    audience: '',
    scope: 'openid profile email',
    authorizationParams: {
      redirect_uri: `${window.location.origin}/auth-callback`
    },
    errorPath: '/access-denied'
  } as AuthConfig
};
